export const AppString = {
    USERS_DOC: 'users',
    Poll_Doc: 'poll',
    PollAdmin_Col: 'pollAdmin',
    PollAdmin_Doc: 'adminAccess',
    QNA: 'qna2d',
    QNAREPLY: 'qna2dreply',
    //USING
    QNAL1: 'qnal1',
    QNAREPLYL1: 'qnareplyl1',
    QNAL2: 'qnal2',
    QNAREPLYL2: 'qnareplyl2',
    QNAL3: 'qnal3',
    QNAREPLYL3: 'qnareplyl3',
    Poll_Doc1: 'Poll_1',
    PollAdmin_Col1: 'poll1Admin',
    PollAdmin_Doc1: 'adminAccess',
    Poll_Doc2: 'Poll_2',
    PollAdmin_Col2: 'poll2Admin',
    PollAdmin_Doc2: 'adminAccess',
    Poll_Doc3: 'Poll_3',
    PollAdmin_Col3: 'poll3Admin',
    PollAdmin_Doc3: 'adminAccess',
}