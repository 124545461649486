// eslint-disable-next-line no-unused-vars
import React, { Component } from "react";
import Menu from "./Menu";
import Submenu from "./Submenu";
import ContextSubmenu from "./ContextSubmenu";

import Qna from "./auditoriums/QAndA";
import Poll from "./auditoriums/PublicPoll";

import Profile from "./Profile";
import { createHashHistory } from 'history'
import { FirebaseContext } from "../firebase";
import AppQna from "../component/App1";
// import PublicChat from "./publicChat/publicChat";
import { isMobileOnly, ConsoleView } from 'react-device-detect';
// import ChatLoader from "./ChatLoader";
import VideoCall from "./videoCalling/VideoCall";
import CloseButton from './close1.png';
import Bar from './bar.png';
import Social from "./Social";
import SocialM from "./SocialM";
import SocialR from "./SocialR";

import QNAL1 from "../qnal1/QNAL1";
//  import QNAL2 from "../qnal2/QNAL2";
//  import QNAL3 from "../qnal3/QNAL3";
import POLL1 from "./auditoriums/POLL1";
//  import POLL2 from "./auditoriums/POLL2";
//  import POLL3 from "./auditoriums/POLL3";

import Agenda from "./Agenda";
import { authx, rdbx, rdatabasex, dbx } from "../firebase/firebase";
import TutorialImage from './Tutorial.png';
import TutorialImage2 from './Tutorial2.png';     
import swal from 'sweetalert';

//for daily co
import Dailyco from "./dailyco/dailyco";   
import { TrackPublication } from "twilio-video";


// end of daily co

export const history = createHashHistory()

var tImage = null;
var audi1_CurrentSession = 0;
 var audi2_CurrentSession = 0;
 var audi3_CurrentSession = 0;
var slider=false;



///////////////MAIN MENU/////////////////////
const menuItems = [   
    {
        id: "home-session",
        name: "Lobby",
        class: "icon-lobby",
        level: 0,
        enabled: true,
    },
    { 
        id: 'conferencehall',
        name: "Audi", 
        class: "icon-auditorium", 
        level: 0, 
        enabled: true, 
    },
    { 
        id: 'Exhibits',//pavillion
        name: "Exhibits", 
        class: "icon-exhibits", 
        level: 0, 
        enabled: true,
    },
    { 
        id: 'metting', 
        name: "Meeting", 
        class: "icon-breakout", 
        isContextMenuTitle: true,
        contextMenuTitle: "Meeting Room",
        level: 0, 
        enabled: true,
        subMenus: [
            { id: 'metting', name: "DBR One", isHighlighted: false, level: 1 },
            { id: 'metting2', name: "DBR Two", isHighlighted: false, level: 1 },
            { id: 'metting3', name: "DBR Three", isHighlighted: false, level: 1 },         
        ],

    },
    { id: 'lounge', name: "Lounge", class: "icon-lounge", level: 0, enabled: true},
    { id: 'profile', name: "Profile", class: "icon-profile", level: 0, enabled: true },
    // { id: 'feedback', name: "Feedback", class: "icon-feedback", level: 0, enabled: true },
];
/////////////// END /////////////////////

///////////////AUDITORIUM INSIDE SUB MENU/////////////////////
const conferencehall = [
    { id: 'backToLobby', name: "Back", class: "icon-angle-back", level: 0, enabled: true },
    { id: 'showQna1', name: "Q&A", class: "icon-qna", level: 0, enabled: true, },
    { id: 'showPoll1', name: "Poll", class: "icon-poll", level: 0, enabled: true }
];




/////////////// END /////////////////////


///////////////BREAKEOUT ROOM INSIDE SUB MENU/////////////////////
const Breakout1QP = [
    { id: 'backToLobby', name: "Back", class: "icon-angle-back", level: 0, enabled: true },
    { id: 'uihider', name: "Hide Menu", class: "icon-hideMenu", level: 0, enabled: true },
];

/////////////// END /////////////////////



///////////////LOUNGE INSIDE SUB MENU/////////////////////

const Lounge = [
    { id: 'backToLobby', name: "Back", class: "icon-angle-back", level: 0, enabled: true },
    { id: 'chat', name: "Chat", class: "icon-chat", level: 0, enabled: true },
];

const Exhibits = [
    { id: 'backToLobby', name: "Back", class: "icon-angle-back", level: 0, enabled: true },    
];

/////////////// END /////////////////////


///////////////LOUNGE INSIDE SUB MENU/////////////////////

const AllBack = [
    { id: 'backToLobby', name: "Back", class: "icon-angle-back", level: 0, enabled: true },
];

/////////////// END /////////////////////


const SessionQP = [
    { id: 'home-sessionQP', name: "Back", class: "icon-angle-back", level: 0, enabled: true },
    { id: 'qna-session', name: "Q&A", class: "icon-qna", level: 0, enabled: true, },
    { id: 'poll-session', name: "Poll", class: "icon-poll", level: 0, enabled: true }
];


 const Breakout = [
     { id: 'home-breakout', name: "Back", class: "icon-angle-back", level: 0, enabled: true },
     { id: 'meeting', name: "Meeting", class: "icon-phone", level: 0, enabled: true },
 ];

const LD = [
    { id: 'home-session', name: "Back", class: "icon-angle-back", level: 0 , enabled: true },
    { id: 'ld1', name: "L&D 1", class: "icon-stall", level: 0, enabled: true, method: "mobileAuditorium" },
    { id: 'ld2', name: "L&D 2", class: "icon-stall", level: 0, enabled: true, method: "mobileAuditorium"},
    { id: 'ld3', name: "L&D 3", class: "icon-stall", level: 0, enabled: true, method: "mobileAuditorium" }
];

const LD1QP = [
    { id: 'home-l1', name: "Back", class: "icon-angle-back", level: 0, method:"mobileBack", enabled: true },
    { id: 'qna-l1', name: "Q&A", class: "icon-qna", level: 0, enabled: true, },
    { id: 'poll-l1', name: "Poll", class: "icon-poll", level: 0, enabled: true },
    { id: 'ld2', name: "L&D 2", class: "icon-stall", level: 0, enabled: true, method: "mobileAuditorium"},
    { id: 'ld3', name: "L&D 3", class: "icon-stall", level: 0, enabled: true, method: "mobileAuditorium" },
    { id: 'call', name: "Call", class: "icon-phone", level: 0, subMenus:[], enabled: false }
];

const LD2QP = [
    { id: 'home-l2', name: "Back", class: "icon-angle-back", level: 0,method:"mobileBack" , enabled: true },
    { id: 'qna-l2', name: "Q&A", class: "icon-qna", level: 0, enabled: true, },
    { id: 'poll-l2', name: "Poll", class: "icon-poll", level: 0, enabled: true },
    { id: 'ld1', name: "L&D 1", class: "icon-stall", level: 0, enabled: true, method: "mobileAuditorium" },
    { id: 'ld3', name: "L&D 3", class: "icon-stall", level: 0, enabled: true, method: "mobileAuditorium" },
    { id: 'call', name: "Call", class: "icon-phone", level: 0, subMenus:[], enabled: false }
];

const LD3QP = [
    { id: 'home-l3', name: "Back", class: "icon-angle-back", level: 0,method:"mobileBack" , enabled: true },
    { id: 'qna-l3', name: "Q&A", class: "icon-qna", level: 0, enabled: true, },
    { id: 'poll-l3', name: "Poll", class: "icon-poll", level: 0, enabled: true },
    { id: 'ld1', name: "L&D 1", class: "icon-stall", level: 0, enabled: true, method: "mobileAuditorium" },
    { id: 'ld2', name: "L&D 2", class: "icon-stall", level: 0, enabled: true, method: "mobileAuditorium"},
    { id: 'call', name: "Call", class: "icon-phone", level: 0, subMenus:[], enabled: false }
];

const InfoDeskMenu = [
    { id: 'home-session', name: "Back", class: "icon-angle-back", level: 0,method:"mobileBack" , enabled: true },
    { id: 'agenda', name: "Agenda", class: "icon-agenda", level: 0, enabled: true, method: "showAgenda"},
    { id: 'video', name: "video", class: "icon-video", level: 0, enabled: true, method: "showInfoDeskVideo" },
    { id: 'call', name: "Call", class: "icon-phone", level: 0, subMenus:[], enabled: false }
];


const MenuStates =
{
    Home: 1,
    Auditorium: 2,
    Halls: 3,
    Chat: 4,
    Profile: 5,
    none: 0
}

class Home extends Component {
    static contextType = FirebaseContext;

    constructor(props) {
        super(props);
        this.state = { 
            menuItems: menuItems, 
            activeMenuLevel0: null,
            activeMenuLevel1: null, 
            activeMenuLevel2: null, 
            currentMenuState: MenuStates.none,
            lastActiveMenu: null, 
            user: null, 
            userX: {},
            uidx: null,
            showVideoCall: false,   
            showNotification: false,
            notification: null, 
            room: null, 
            callWatcher: null, 
            callMenuActive: false, 
            isInteractable: false, 
            activeSideMenu:menuItems,
            SessionQP:SessionQP,
            Lounge:Lounge,
            Exhibits:Exhibits,
            Breakout:Breakout,
            AllBack:AllBack,
            LD1QP:LD1QP,
            LD2QP:LD2QP,
            LD3QP:LD3QP,
            LD:LD,
            Infodsek: InfoDeskMenu,
            showQna: false,
            showPoll: false,
            showQna1: false,
            showPoll1: false,           
            isLobby: false,
            isShare: false,
            showHelp:false,
            isHelp1: false,
            isHelp2: false,
            isHelpShow1: false,
            isHelpShow2: false,
            updateAnalyticsOnScreen: "0",
            showAdminNotification:false,
            textContent:"",
            landDOpened: false,
            moveToSession: false,
            sessionOpenned: false,
            audiURL1:'',   
            zoomURL1:'',     
            zoomURL2:'',
            zoomURL3:'',
                  
            //for daily co
            updateLiveParticipantVideoCall: false,
            enterbreakout: false, 
            sidebar:false,          
        };

        this.handleClick = this.handleClick.bind(this);
        this.handleContextMenu = this.handleContextMenu.bind(this);
        this.hideMenuLevel1 = this.hideMenuLevel1.bind(this);
        this.handleSubmenuClick = this.handleSubmenuClick.bind(this);
        this.onHeadingClick = this.onHeadingClick.bind(this);
        this.handleSubContextMenuLevel1 = this.handleSubContextMenuLevel1.bind(this);
        this.handleSubContextMenuLevel2 = this.handleSubContextMenuLevel2.bind(this);
        this.onSwipeUpClicked = this.onSwipeUpClicked.bind(this);
        this.joinCall = this.joinCall.bind(this);
        this.onCallDisconnect = this.onCallDisconnect.bind(this);
        this.showNotification = this.showNotification.bind(this);
        this.startCallWatcher = this.startCallWatcher.bind(this);
        this.endCallWatcher = this.endCallWatcher.bind(this);
        this.updateJoinCallMenu = this.updateJoinCallMenu.bind(this);
        this.removeJoinCallMenu = this.removeJoinCallMenu.bind(this);
        this.listener = null;
        this.videoCall = React.createRef();
        this.hideAllMenu = this.hideAllMenu.bind(this);
        this.resetSubMenuActiveId = this.resetSubMenuActiveId.bind(this);
        this.hideShare = this.hideShare.bind(this);
        this.showHelp1 = this.showHelp1.bind(this);
        this.showHelp2 = this.showHelp2.bind(this);
        this.hideHelp1 = this.hideHelp1.bind(this);
        this.hideHelp2 = this.hideHelp2.bind(this);
        this.HelpShow1 = this.HelpShow1.bind(this);
        this.HelpShow2 = this.HelpShow2.bind(this);
        this.HelpHide1 = this.HelpHide1.bind(this);
        this.HelpHide2 = this.HelpHide2.bind(this);
        this.getShare = this.getShare.bind(this);
        this.getLandDStatus = this.getLandDStatus.bind(this);
        this.getSessionStatus = this.getSessionStatus.bind(this);           
       
     //   this.user_ActiveStatus = this.user_ActiveStatus.bind(this);
    //    this.Analytics_LiveUsersCountTracker = this.Analytics_LiveUsersCountTracker.bind(this);

        this.attachAdminControlListener = this.attachAdminControlListener.bind(this);
        this.removeAdminControlListener = this.removeAdminControlListener.bind(this);
       
        window.ReactHomeManager = this;
        this.isMobileForTest=false;
          //to pass to daily co 
          this.callEnterBreakout= this.callEnterBreakout.bind(this);
          this.LiveParticipantListFromFirebase = new Map();
          this.canStartVideoCall = false;         
    }
   
    componentWillMount()
    {
        if(this.authLis)
            this.authLis();
    }

    hideShare()
    {
        this.isShare = false;
        this.setState({isShare:false});
        this.context.db.collection("InfinityRunner").doc(this.state.uidx).update({
            isShare: false
        })
        .then(function() {
            //console.log("Successfully Shared!");
        })
        .catch(function(error) {
            //console.error("Error Share: ", error);
        });
    }

    hideHelp1()
    {
        this.isHelp1 = false;
        
    }

    hideHelp2()
    {
        this.isHelp2 = false;
       
    }

    showHelp1()
    {
        this.isHelp1 = true;
       
    }

    showHelp2()
    {
        this.isHelp2 = true;
        
    }

    ShowTutorialAgain=()=>{
        //this.state.showHelp=flag;
       // this.setState({showHelp:flag});
  
        window.canvasManager.ShowTutorial();
    }

    ShowTutorialIcon=(flag)=>{
        this.setState({showHelp:flag});
        this.state.showHelp=flag;
    }

    HelpShow1()
    {
        this.isHelpShow1 = true;
       
    }

    HelpShow2()
    {
        this.isHelpShow2 = true;
       
    }

    HelpHide1()
    {
        this.isHelpShow1 = false;
       
    }

    HelpHide2()
    {
        this.isHelpShow2 = false;
       
    }

    getShare = async () => {
        await this.context.db.collection("InfinityRunner").doc(this.state.uidx).onSnapshot(function(doc) {

            if(doc.data())
            {
                if(doc.data().isShare === true)
                {
                    this.isShare = true;
                    this.setState({ isShare: true});
                }
            }

            }.bind(this));
        }    
    componentDidMount() {
        const that = this;
        try {

            this.authLis =  this.context.auth.onAuthStateChanged((user) => {
                if(user)
                {
                    this.setState({user: user});
                    this.setState({uidx: user.uid});

                      // for daily co                
                      this.context.homeVideoPrepareFunction = this.addTownHallLisenter;
                      // 

            
        //Rishabh Start
        this.user_ExistInTableCheck();
        // this.Analytics_AuditoriumCurrentSession();
        //Rishabh End
                this.getShare();
                this.GetCallStartStatusforStreamVideo();
                }
                 else
                {
                    window.location.href = "/index.html";
                    this.setState({user: null})
                }
            });

            if (isMobileOnly) {
               // console.log("found mobile");
                this.setState({
                    activeMenuLevel0: menuItems[0]
                });
                this.isMobileForTest=true;
                
                var self = this;
                //window.startMobileCanvasApp();
                window.startWindowCanvasApp();
                this.ToggleUIInteractbleState(false);

                document.addEventListener('swiped-up', function(e) {
                    if(e.target.id === "application-canvas")
                    {
                        //console.log("swipefound Up!!");
                        self.onSwipeUpClicked(true);
                    } // element that was swiped
                });
                document.addEventListener('swiped-down', function(e) {
                    if(e.target.id === "application-canvas")
                    {
                        // console.log("swipefound Down!!");
                        self.onSwipeUpClicked(false);
                    } 
                });

                document.addEventListener('swiped-right', function(e) {
                    if(e.target.id === "application-canvas")
                    {
                        window.canvasManager.moveCameraRight();
                        // console.log("swipefound Right!!");
                    } 
                });
                

                document.addEventListener('swiped-left', function(e) {
                    if(e.target.id === "application-canvas")
                    {
                        window.canvasManager.moveCameraLeft();
                        // console.log("swipefound Left!!");
                    } 
                  });
            } else {
                console.log("found desktop");
                this.isMobileForTest=false;
                window.startWindowCanvasApp();
                this.ToggleUIInteractbleState(false);
            }
            
        } catch (e) {
            console.error(e);
        }
        tImage = TutorialImage;
        this.hideAllMenu();
        this.attachAdminControlListener();
        //this.hideShare();
        //this.isShare = true;
        //this.setState({ isShare: true});
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
        this.removeAdminControlListener();

        
        //for dailyco
        this.removeTownHallLisenter();
    }
 
    ////////////////////////////////////////ANALYTICS/////////////////////////////////////////////////////////


   // Rishabh
    //Rishabh Start
    user_AnalyticsHandler = async (stateValue, stateMode) =>{

        console.log(stateValue, stateMode);
        try
        {
          if(stateValue == 2 && stateMode == "Enter")
          {
            
            //rdbx.ref('/user_analytics/' + this.auth.currentUser.uid + '/LnD_Connect1_Visit_Status').set(rdatabasex.ServerValue.TIMESTAMP); 
            rdbx.ref('/user_analytics_vccircle/' + authx.currentUser.uid + '/Z01_CurrentRoom').set("Lobby");
            rdbx.ref('/user_analytics_vccircle/' + authx.currentUser.uid + '/G04_LY').set("Y");
          }
          else if(stateValue == 4 && stateMode == "Enter")
          {
            rdbx.ref('/user_analytics_vccircle/' + authx.currentUser.uid + '/Z01_CurrentRoom').set("INFO");
            rdbx.ref('/user_analytics_vccircle/' + authx.currentUser.uid + '/D01_INFO').set("Y");
          }
          else if(stateValue == 6 && stateMode == "Enter")
          {
            rdbx.ref('/user_analytics_vccircle/' + authx.currentUser.uid + '/Z01_CurrentRoom').set("CONF");
            rdbx.ref('/user_analytics_vccircle/' + authx.currentUser.uid + '/F01_CONF').set("Y");
          }
          else if(stateValue == 8 && stateMode == "Enter")
          {
            rdbx.ref('/user_analytics_vccircle/' + authx.currentUser.uid + '/Z01_CurrentRoom').set("Lounge");
            rdbx.ref('/user_analytics_vccircle/' + authx.currentUser.uid + '/G01_LG').set("Y");
          }        
          else if(stateValue == 12 && stateMode == "Enter")
          {
            console.log("Prime pavillion");
            rdbx.ref('/user_analytics_vccircle/' + authx.currentUser.uid + '/Z01_CurrentRoom').set("PRMPVLN");
            rdbx.ref('/user_analytics_vccircle/' + authx.currentUser.uid + '/G01_PRMPVLN').set("Y");            
          }
          else if(stateValue == 14 && stateMode == "Enter")
          {
            rdbx.ref('/user_analytics_vccircle/' + authx.currentUser.uid + '/Z01_CurrentRoom').set("DBR1");
            rdbx.ref('/user_analytics_vccircle/' + authx.currentUser.uid + '/G01_DBR1').set("Y");          
          }  
          else if(stateValue == 19 && stateMode == "Enter")
          {
            rdbx.ref('/user_analytics_vccircle/' + authx.currentUser.uid + '/Z01_CurrentRoom').set("DBR2");
            rdbx.ref('/user_analytics_vccircle/' + authx.currentUser.uid + '/G01_DBR2').set("Y");          
          }  

          else if(stateValue == 21 && stateMode == "Enter")
          {
            rdbx.ref('/user_analytics_vccircle/' + authx.currentUser.uid + '/Z01_CurrentRoom').set("DBR3");
            rdbx.ref('/user_analytics_vccircle/' + authx.currentUser.uid + '/G01_DBR3').set("Y");          
          }  
         
          else if(stateValue == 100 )
          {
            if(stateMode == "AG")           
            rdbx.ref('/user_analytics_vccircle_Infodesk/' + authx.currentUser.uid + '/D01_INFO_AG').set("Y");

            if(stateMode == "survey")           
            rdbx.ref('/user_analytics_vccircle_Infodesk/' + authx.currentUser.uid + '/D01_INFO_EIS').set("Y");

            else if(stateMode == "KB")
            rdbx.ref('/user_analytics_vccircle_Infodesk/' + authx.currentUser.uid + '/D01_INFO_KB').set("Y");   

            else if(stateMode == "SP")
            rdbx.ref('/user_analytics_vccircle_Infodesk/' + authx.currentUser.uid + '/D01_INFO_SP').set("Y");   
            
          }

          else if(stateValue == 101 )
          {
            if(stateMode == "dell")           
            rdbx.ref('/user_analytics_vccircle_Lobby/' + authx.currentUser.uid + '/J02_L_DT').set("Y");

            else if(stateMode == "resetleader")
            rdbx.ref('/user_analytics_vccircle_Lobby/' + authx.currentUser.uid + '/J02_L_RL').set("Y");
            
            else if(stateMode == "tweetlive")
            rdbx.ref('/user_analytics_vccircle_Lobby/' + authx.currentUser.uid + '/J02_L_TC').set("Y");

            else if(stateMode == "lobbyagenda")
            rdbx.ref('/user_analytics_vccircle_Lobby/' + authx.currentUser.uid + '/J02_L_VA').set("Y");
          }

          else if(stateValue == 112)
          {
            if(stateMode == "BC")           
            rdbx.ref('/user_analytics_vccircle_TRIPG/' + authx.currentUser.uid + '/D02_BC').set("Y");

            else if(stateMode == "B1")
            rdbx.ref('/user_analytics_vccircle_TRIPG/' + authx.currentUser.uid + '/B1').set("Y");
            
            else if(stateMode == "V1")
            rdbx.ref('/user_analytics_vccircle_TRIPG/' + authx.currentUser.uid + '/V1').set("Y");
         
          }

          else if(stateValue == 113)
          {
            if(stateMode == "BC")           
            rdbx.ref('/user_analytics_vccircle_TRUSTS/' + authx.currentUser.uid + '/D02_BC').set("Y");
          }
     
          else if(stateValue == 114)
          {
            if(stateMode == "BC")           
            rdbx.ref('/user_analytics_vccircle_ZWAYAM/' + authx.currentUser.uid + '/D02_BC').set("Y");

            else if(stateMode == "B1")
            rdbx.ref('/user_analytics_vccircle_ZWAYAM/' + authx.currentUser.uid + '/B1').set("Y");
         
          }

          else if(stateValue == 115)
          {
            if(stateMode == "BC")           
            rdbx.ref('/user_analytics_vccircle_EDGEI/' + authx.currentUser.uid + '/D02_BC').set("Y");
          
          }

          else if(stateValue == 116)
          {
            if(stateMode == "BC")           
            rdbx.ref('/user_analytics_vccircle_HYLYT/' + authx.currentUser.uid + '/D02_BC').set("Y");

            else if(stateMode == "V1")
            rdbx.ref('/user_analytics_vccircle_HYLYT/' + authx.currentUser.uid + '/V1').set("Y");            
          
          }

          else if(stateValue == 117)
          {
            if(stateMode == "BC")           
            rdbx.ref('/user_analytics_vccircle_MAKARAND/' + authx.currentUser.uid + '/D02_BC').set("Y");
          }

          else if(stateValue == 118)
          {
            if(stateMode == "BC")           
            rdbx.ref('/user_analytics_vccircle_TECHCIRCLE/' + authx.currentUser.uid + '/D02_BC').set("Y");
          }

          else if(stateValue == 119)
          {
            if(stateMode == "BC")           
            rdbx.ref('/user_analytics_vccircle_AUTOMATION/' + authx.currentUser.uid + '/D02_BC').set("Y");

            else if(stateMode == "V2")
            rdbx.ref('/user_analytics_vccircle_AUTOMATION/' + authx.currentUser.uid + '/V2').set("Y");
            
            else if(stateMode == "V1")
            rdbx.ref('/user_analytics_vccircle_AUTOMATION/' + authx.currentUser.uid + '/V1').set("Y");

            else if(stateMode == "V3")
            rdbx.ref('/user_analytics_vccircle_AUTOMATION/' + authx.currentUser.uid + '/V3').set("Y");

            else if(stateMode == "B2")
            rdbx.ref('/user_analytics_vccircle_AUTOMATION/' + authx.currentUser.uid + '/B2').set("Y");
            
            else if(stateMode == "B1")
            rdbx.ref('/user_analytics_vccircle_AUTOMATION/' + authx.currentUser.uid + '/B1').set("Y");

            else if(stateMode == "B3")
            rdbx.ref('/user_analytics_vccircle_AUTOMATION/' + authx.currentUser.uid + '/B3').set("Y");

            
          }

          else if(stateValue == 120)
          {
            if(stateMode == "BC")           
            rdbx.ref('/user_analytics_vccircle_HPE/' + authx.currentUser.uid + '/D02_BC').set("Y");

            else if(stateMode == "B1")
            rdbx.ref('/user_analytics_vccircle_HPE/' + authx.currentUser.uid + '/B1').set("Y");
            
            else if(stateMode == "B2")
            rdbx.ref('/user_analytics_vccircle_HPE/' + authx.currentUser.uid + '/B2').set("Y");

            else if(stateMode == "V1")
            rdbx.ref('/user_analytics_vccircle_HPE/' + authx.currentUser.uid + '/V1').set("Y");

            else if(stateMode == "V2")
            rdbx.ref('/user_analytics_vccircle_HPE/' + authx.currentUser.uid + '/V2').set("Y");

          }


          else if(stateValue == 211 && stateMode == "Enter")
          {             
            rdbx.ref('/user_analytics_vccircle/' + authx.currentUser.uid + '/Z01_CurrentRoom').set("TripGain");
            rdbx.ref('/user_analytics_vccircle_TRIPG/' + authx.currentUser.uid + '/A12_tripgain_Visited').set("Y");
          } 
          else if(stateValue == 212 && stateMode == "Enter")
          {
            rdbx.ref('/user_analytics_vccircle/' + authx.currentUser.uid + '/Z01_CurrentRoom').set("TrustSignal");
            rdbx.ref('/user_analytics_vccircle_TRUSTS/' + authx.currentUser.uid + '/A13_trustsignal_Visited').set("Y");
          }
           else if(stateValue == 213 && stateMode == "Enter")
          {
            rdbx.ref('/user_analytics_vccircle/' + authx.currentUser.uid + '/Z01_CurrentRoom').set("Zwayam");
            rdbx.ref('/user_analytics_vccircle_ZWAYAM/' + authx.currentUser.uid + '/A14_zwayam_Visited').set("Y");
          } 
          else if(stateValue == 214 && stateMode == "Enter")
          {
            rdbx.ref('/user_analytics_vccircle/' + authx.currentUser.uid + '/Z01_CurrentRoom').set("EdgeInsights");
            rdbx.ref('/user_analytics_vccircle_EDGEI/' + authx.currentUser.uid + '/A15_edgeinsights_Visited').set("Y");
          } 
          else if(stateValue == 215 && stateMode == "Enter")
          {
            rdbx.ref('/user_analytics_vccircle/' + authx.currentUser.uid + '/Z01_CurrentRoom').set("HYLYT");
            rdbx.ref('/user_analytics_vccircle_HYLYT/' + authx.currentUser.uid + '/A16_hylyt_Visited').set("Y");
          } 
          else if(stateValue == 216 && stateMode == "Enter")
          {
            rdbx.ref('/user_analytics_vccircle/' + authx.currentUser.uid + '/Z01_CurrentRoom').set("MakarandSystems");
            rdbx.ref('/user_analytics_vccircle_MAKARAND/' + authx.currentUser.uid + '/A17_makaranadsystems_Visited').set("Y");
          } 
          else if(stateValue == 217 && stateMode == "Enter")
          {            
            rdbx.ref('/user_analytics_vccircle/' + authx.currentUser.uid + '/Z01_CurrentRoom').set("TechCircle");
            rdbx.ref('/user_analytics_vccircle_TECHCIRCLE/' + authx.currentUser.uid + '/A18_techcircle_Visited').set("Y");
          } 
          else if(stateValue == 218 && stateMode == "Enter")
          {
            rdbx.ref('/user_analytics_vccircle/' + authx.currentUser.uid + '/Z01_CurrentRoom').set("AutomationAnywhere");
            rdbx.ref('/user_analytics_vccircle_AUTOMATION/' + authx.currentUser.uid + '/A19_automationanywhere_Visited').set("Y");
          }
           else if(stateValue == 219 && stateMode == "Enter")
          {
            rdbx.ref('/user_analytics_vccircle/' + authx.currentUser.uid + '/Z01_CurrentRoom').set("HPEnterprise");
            rdbx.ref('/user_analytics_vccircle_HPE/' + authx.currentUser.uid + '/A20_hpenterprise_Visited').set("Y");
          }
        
        }catch(error)
        {
          var errorCode = error.code;
          var errorMessage = error.message;
          console.log("code: "+errorCode+" & ErrorMsg: "+errorMessage);
        }
      };

      //Rishabh Start
    


      //Rishabh End

    //Rishabh Start
    // Analytics_CheckForMenuStateChange() {
    //     var self = this;
    //     if(window.lastMenu === 4){
    //         self.setState({
    //             updateAnalyticsOnScreen: window.analytics_SessionRoom_ActiveUserCount
    //         });
    //     }
    //     else if(window.lastMenu === 6){
    //         self.setState({
    //             updateAnalyticsOnScreen: window.analytics_Connect1Room_ActiveUserCount
    //         });
    //     }
    //     else if(window.lastMenu === 14){
    //         self.setState({
    //             updateAnalyticsOnScreen: window.analytics_Connect2Room_ActiveUserCount
    //         });
    //     }
    //     else if(window.lastMenu === 15){
    //         self.setState({
    //             updateAnalyticsOnScreen: window.analytics_Connect3Room_ActiveUserCount
    //         });
    //     }
    //     else{
    //         self.setState({
    //             updateAnalyticsOnScreen: window.analytics_liveUsers
    //         });
    //     }
    // }

    user_ExistInTableCheck() {
        let self = this
        var query = rdbx.ref('/user_analytics_vccircle/' + authx.currentUser.uid + '/B01_LogInTime');
        query.once("value").then(function(snapshot) {
        //if(snapshot.val() !== null){
        if(snapshot.exists()){
            //user already exist in analytics table
            console.log(snapshot.val());
            console.log("Already Exist");
            // self.user_ActiveStatus();
            // self.Analytics_LiveUsersCountTracker();
        }else{
            console.log("this is New Entry");
            console.log(snapshot.val());
            console.log("New Entry");
            rdbx.ref('/user_analytics_vccircle/' + authx.currentUser.uid).set({
            A01_Email: authx.currentUser.email,
            B01_LogInTime: rdatabasex.ServerValue.TIMESTAMP,                 
            F01_CONF: "N",     
            D01_INFO: "N",   
            G01_LG: "N",
            G01_PRMPVLN: "N",
            G01_DBR1: "N",   
            G01_DBR2: "N",  
            G01_DBR3: "N",
            J02_L_DT: "N",     
            J02_L_VA: "N",  
            J02_L_RL: "N",  
            J02_L_TC: "N",       
            Z01_CurrentRoom: "none"
            });
            rdbx.ref('/user_analytics_vccircle/' + authx.currentUser.uid + '/B01_LogInTime').once('value').then(function(snapshot){
            var mDate = (snapshot.val());
            var myDate = new Date(mDate);
            myDate = myDate.getHours()+":"+myDate.getMinutes()+","+myDate.getDate()+"/"+(myDate.getMonth()+1);
            rdbx.ref('/user_analytics_vccircle/' + authx.currentUser.uid + '/B01_LogInTime').set(myDate.toString());
            });            
         
            // [TRIP-GAIN]
            rdbx.ref('/user_analytics_vccircle_TRIPG/' + authx.currentUser.uid).set({
                A01_Email: authx.currentUser.email,
                A02_StallID: "12-TRIPG",
                A03_Visited: "N", B1: "N", V1: "N",  D02_BC:"N"
            }); //Stall-8 
            rdbx.ref('/user_analytics_vccircle_TRUSTS/' + authx.currentUser.uid).set({
                A01_Email: authx.currentUser.email,
                A02_StallID: "13-TRUSTS",
                A03_Visited: "N", D02_BC:"N"
            }); //Stall-8 
            rdbx.ref('/user_analytics_vccircle_ZWAYAM/' + authx.currentUser.uid).set({
                A01_Email: authx.currentUser.email,
                A02_StallID: "14-ZWAYAM",
                A03_Visited: "N", B1: "N", D02_BC:"N"
            }); //Stall-8 
            rdbx.ref('/user_analytics_vccircle_EDGEI/' + authx.currentUser.uid).set({
                A01_Email: authx.currentUser.email,
                A02_StallID: "15-EDGEI",
                A03_Visited: "N", D02_BC:"N"
            }); //Stall-8 
            rdbx.ref('/user_analytics_vccircle_HYLYT/' + authx.currentUser.uid).set({
                A01_Email: authx.currentUser.email,
                A02_StallID: "16-HYLYT",
                A03_Visited: "N", V1: "N", D02_BC:"N"
            }); //Stall-17 
            rdbx.ref('/user_analytics_vccircle_MAKARAND/' + authx.currentUser.uid).set({
                A01_Email: authx.currentUser.email,
                A02_StallID: "18-MAKARAND",
                A03_Visited: "N", D02_BC:"N"
            });  
            // [TECH-CIRCLE]
            rdbx.ref('/user_analytics_vccircle_TECHCIRCLE/' + authx.currentUser.uid).set({
                A01_Email: authx.currentUser.email,
                A02_StallID: "18-TECHCIRCLE",
                A03_Visited: "N", D02_BC:"N"
            });
              // [AUTOMATION]
            rdbx.ref('/user_analytics_vccircle_AUTOMATION/' + authx.currentUser.uid).set({
                A01_Email: authx.currentUser.email,
                A02_StallID: "19-AUTOMATION",
                A03_Visited: "N", B1: "N", B2: "N", B3: "N", V1: "N", V2: "N", V3: "N",
                D02_BC:"N"
            });
              //[HP-]-20 
            rdbx.ref('/user_analytics_vccircle_HPE/' + authx.currentUser.uid).set({
                A01_Email: authx.currentUser.email,
                A02_StallID: "20-HP",
                A03_Visited: "N", B1: "N", B2: "N",  V1: "N", V2: "N",
                D02_BC:"N"
            });
                
        }
        self.user_ActiveStatus();
        self.Analytics_LiveUsersCountTracker();
        
        });
    }

    user_ActiveStatus() {

        // let self = this
        var uid = authx.currentUser.uid;
        
        var userStatusDatabaseRef = rdbx.ref('/user_analytics_vccircle/' + uid + '/Z02_State/');
        var userCurrentRoomRef = rdbx.ref('/user_analytics_vccircle/' + uid + '/Z01_CurrentRoom/')
        //console.log(userStatusDatabaseRef)

        var isOfflineForDatabase = {
            State: 'offline',
        };

        var isOnlineForDatabase = {
            State: 'online',
        };

        rdbx.ref('.info/connected').on('value', function(snapshot) {
            if (snapshot.val() == false) {
            return;
            };
            userStatusDatabaseRef.onDisconnect().set(isOfflineForDatabase).then(function() {
            userStatusDatabaseRef.set(isOnlineForDatabase);
            });
        });

             userCurrentRoomRef.onDisconnect().set("none");           
             userStatusDatabaseRef.onDisconnect().set("offline");

        // self.Analytics_LiveUsersCountTracker();
      };
      
      Analytics_LiveUsersCountTracker() {
        
        var query =  this.context.database.ref("user_analytics_vccircle").orderByKey();
        query.on("value", (snapshot) => {
            // console.log(snapshot)

            window.analytics_liveUsers=0;
            window.analytics_liveUsers_lobby=0;
            window.analytics_liveUsers_lounge=0;
            window.analytics_liveUsers_DBR1=0;
            window.analytics_liveUsers_DBR2=0;
            window.analytics_liveUsers_DBR3=0;
            window.analytics_liveUsers_CONF=0;           
            window.analytics_liveUsers_PRMPVLN=0;
            window.analytics_liveUsers_INFO=0;
           
            window.analytics_liveUsers_exhibits=0;            
            window.analytics_liveUsers_exhibitsStall12=0;
            window.analytics_liveUsers_exhibitsStall13=0;
            window.analytics_liveUsers_exhibitsStall14=0;
            window.analytics_liveUsers_exhibitsStall15=0;
            window.analytics_liveUsers_exhibitsStall16=0;
            window.analytics_liveUsers_exhibitsStall17=0;
            window.analytics_liveUsers_exhibitsStall18=0;
            window.analytics_liveUsers_exhibitsStall19=0;
            window.analytics_liveUsers_exhibitsStall20=0;

            

            snapshot.forEach(function(childSnapshot) {
            var key = childSnapshot.key;
            var childData = childSnapshot.val();
                if(childData.Z02_State && childData.Z02_State.State && childData.Z02_State.State == "online"){
                    window.analytics_liveUsers++;  

                if(childData.Z01_CurrentRoom == "Lobby"){
                    window.analytics_liveUsers_lobby++;
                }
                else if(childData.Z01_CurrentRoom == "Lounge"){
                    window.analytics_liveUsers_lounge++;
                }
                else if(childData.Z01_CurrentRoom == "DBR1"){
                    window.analytics_liveUsers_DBR1++;
                }

                else if(childData.Z01_CurrentRoom == "DBR2"){
                    window.analytics_liveUsers_DBR2++;
                }

                else if(childData.Z01_CurrentRoom == "DBR3"){
                    window.analytics_liveUsers_DBR3++;
                }

                else if(childData.Z01_CurrentRoom == "INFO"){
                    window.analytics_liveUsers_INFO++;
                }               
               
                else if(childData.Z01_CurrentRoom == "CONF"){
                    window.analytics_liveUsers_CONF++;
                }
                else if(childData.Z01_CurrentRoom == "PRMPVLN"){
                    window.analytics_liveUsers_PRMPVLN++;
                }
               
                else if(childData.Z01_CurrentRoom == "TripGain"){
                    window.analytics_liveUsers_exhibitsStall12++;
                }
                else if(childData.Z01_CurrentRoom == "TrustSignal"){
                    window.analytics_liveUsers_exhibitsStall13++;
                }
                else if(childData.Z01_CurrentRoom == "Zwayam"){
                    window.analytics_liveUsers_exhibitsStall14++;
                }
                else if(childData.Z01_CurrentRoom == "EdgeInsights"){
                    window.analytics_liveUsers_exhibitsStall15++;
                }
                else if(childData.Z01_CurrentRoom == "HYLYT"){
                    window.analytics_liveUsers_exhibitsStall16++;
                }
                else if(childData.Z01_CurrentRoom == "MakarandSystems"){
                    window.analytics_liveUsers_exhibitsStall17++;
                }
                else if(childData.Z01_CurrentRoom == "TechCircle"){
                    window.analytics_liveUsers_exhibitsStall18++;
                }
                else if(childData.Z01_CurrentRoom == "AutomationAnywhere"){
                    window.analytics_liveUsers_exhibitsStall19++;
                }
                else if(childData.Z01_CurrentRoom == "HPEnterprise"){
                    window.analytics_liveUsers_exhibitsStall20++;
                }

                if(childData.Z01_CurrentRoom == "TripGain" || childData.Z01_CurrentRoom == "TrustSignal" || childData.Z01_CurrentRoom == "Zwayam" || childData.Z01_CurrentRoom == "EdgeInsights" ||
                childData.Z01_CurrentRoom == "HYLYT" || childData.Z01_CurrentRoom == "MakarandSystems" || childData.Z01_CurrentRoom == "TechCircle"|| childData.Z01_CurrentRoom == "AutomationAnywhere" || childData.Z01_CurrentRoom == "HPEnterprise"){
                 window.analytics_liveUsers_exhibits++;
                }
            }
            })
        })
    }
    //Rishabh End
    //Rishabh End
    
    
    ////////////////////////////////////////ANALYTICS/////////////////////////////////////////////////////////

    //#region  video call 
    handleCall = room =>{
        this.setState({room: room })
        let roomDetails =  room;
        if(roomDetails.endDateTime >= Date.now())
        {
            if(roomDetails.startDateTime <= Date.now() )
            {
                this.showNotification({line1: "Your call has already started", line2: "", timeout: 10000});
                this.updateJoinCallMenu();
                let watcher = setInterval(this.endCallWatcher, 1000);
                this.setState({callWatcher: watcher})

            }else{
                //console.log("Scheduling startCallWatcher");
                let watcher = setInterval(this.startCallWatcher, 1000);
                this.setState({callWatcher: watcher})
            }
        }
    }

    startCallWatcher(){
        if(this.state.user){
            let roomDetails = this.state.room;
            if(roomDetails.startDateTime <=  (Date.now() + 60000))
            {
                clearInterval(this.state.callWatcher);
                this.setState({callWatcher: null});

                this.showNotification({line1: "Your call is about to start", line2: "in a minute", timeout: 40000});
                setTimeout(this.updateJoinCallMenu, 59000);

                let watcher = setInterval(this.endCallWatcher, 1000);
                this.setState({callWatcher: watcher})
            }
        }
    }

    endCallWatcher(){
        if(this.state.user) {
            let roomDetails =  this.state.room;
            if(roomDetails.endDateTime <=  (Date.now() + 60000))
            {
                clearInterval(this.state.callWatcher);
                this.setState({callWatcher: null});

                this.showNotification({line1: "Your call is about to end", line2: "in a minute", timeout: 40000});
                setTimeout(this.removeJoinCallMenu, 59000);
            }
        }
    }

    updateJoinCallMenu(){
        this.setState({callMenuActive: true });
        let menuItems = this.state.menuItems;
        //true
        menuItems[4].enabled = true;
        LD1QP[5].enabled = true;
        LD2QP[5].enabled = true;
        LD3QP[5].enabled = true;
        InfoDeskMenu[3].enabled = true;

        this.setState({menuItems: menuItems });
        
    }

    removeJoinCallMenu(){
       this.setState({callMenuActive: false });
        let menuItems = this.state.menuItems;
        //false
        menuItems[4].enabled = false;

        LD1QP[5].enabled = false;
        LD2QP[5].enabled = false;
        LD3QP[5].enabled = false;
        InfoDeskMenu[3].enabled = false;


        this.setState({menuItems: menuItems });
       if(this.videoCall.current)
       {
           this.videoCall.current.leaveRoom();
       }
    }
    
    joinCall(){
        this.setState({showVideoCall: true});
        window.canvasManager.PauseAudio();
       // console.log("999999999");
    }

    onCallDisconnect(){
        this.setState({showVideoCall: false});
        window.canvasManager.ResumeAudio();
    }
//#endregion

    //#region PlayCanvas Functions
    callReactConnectorFunction(menuItem) {
        if (menuItem.method !== undefined) {
            window.canvasManager[menuItem.method](menuItem);
        }
    }
    //#endregion


//Side Menu Button Click Action Area
    handleClick(event, menuItem) {

        if(event != undefined)
            event.preventDefault();

       // console.log(menuItem);   
        //console.log(menuItem);
        if(this.state.user)
        {
            
            switch(menuItem.id){
                case 'home-session':{
                    break;
                }
                case 'conferencehall':{
                   
                    this.setState({ menuItems:conferencehall });
                    window.canvasManager.LobbyCallsFromReact('goToConferenceHall');
                    this.hideAllMenu();                 
                   
                    break;                  
                }              
                case 'Exhibits':{    
                    this.setState({ menuItems:Exhibits });
                    window.canvasManager.LobbyCallsFromReact('goToPrimePavillion');
                    this.hideAllMenu();                     
                    break;
                }
                case 'metting':{
                  
                    break;
                    if((this.context.isAllowedTownHall && window.FirebaseObj.uservalidforcall && (window.FirebaseObj.meetingsessionbelongs==window.FirebaseObj.meetingsessionnumber))||window.FirebaseObj.meetingsessionbelongs==100)
                    this.setState({ menuItems:Breakout1QP });
                    else
                     this.ToggleMenuLoading(false);
                    
                    this.hideAllMenu(); 
                    window.canvasManager.LobbyCallsFromReact('goToMeeting');
                    
                }
                case 'lounge':{
                    if(!window.FirebaseObj.mediauser)            
                    this.setState({ menuItems:Lounge }); 
                    else
                    this.ToggleMenuLoading(false);
                    window.canvasManager.LobbyCallsFromReact('goToLounge');
                    this.hideAllMenu();                  
                    break;
                }
                case 'profile':{
                    this.handleContextMenu(menuItem);
                    break;
                }
                case 'backToLobby':{
                    window.canvasManager.LobbyCallsFromReact('backToLobby');
                    console.log('Back Clicked');
                    // for daily co                  
                   if(window.FirebaseObj.currentUser && this.state.enterbreakout)
                   {
                    if(window.DailycoManager.state.hasJoinedCall)
                    window.DailycoManager.EndCall();
                    this.callEnterBreakout(false);
                   }                   
                    this.hideAllMenu();
                    break;
                }
                case 'chat':{
                    window.canvasManager.CreatePublicChat();
                    break;
                }
                case 'leaderBoard-show':{
                    window.canvasManager.createMainLeaderBoard();
                    break;
                }
                case 'feedback':{
                    window.canvasManager.createFeedbackform();
                    break;
                }
                case 'uihider':{
                   // console.log(menuItem.id+"menuItem.id");
                    this.DailyCoSidebarhandler();
                    break;
                }
            }
           // this.callReactConnectorFunction(menuItem)
           if(menuItem.subMenus)
           {
               // console.log(menuItem);
                this.handleContextMenu(menuItem);
           }

           this.setState({
                showPoll:false,
                showQna:false,

                showPoll1:false,
                showQna1:false,

                showPoll2:false,
                showQna2:false,

                showPoll3:false,
                showQna3:false,
            });

            this.showPoll1=false;
            this.showQna1=false;
            this.showPoll2=false;
            this.showQna2=false;
            this.showPoll3=false;
            this.showQna3=false;

           //QNA SESSIONS
           switch(menuItem.id){
                case 'showPoll1':{
                    this.showPoll1=true;
                    this.showQna1=false;
                    this.showPoll2=false;
                    this.showQna2=false;
                    this.showPoll3=false;
                    this.showQna3=false;
                    this.setState({showPoll1:false});
                    break;
                }
                case 'showPoll2':{
                    this.showPoll1=false;
                    this.showQna1=false;
                    this.showPoll2=true;
                    this.showQna2=false;
                    this.showPoll3=false;
                    this.showQna3=false;
                    this.setState({showPoll2:false});
                    break;
                }
                case 'showPoll3':{
                    this.showPoll1=false;
                    this.showQna1=false;
                    this.showPoll2=false;
                    this.showQna2=false;
                    this.showPoll3=true;
                    this.showQna3=false;
                    this.setState({showPoll3:false});
                    break;
                }
                case 'showQna1':{
                    this.showPoll1=false;
                    this.showQna1=true;
                    this.showPoll2=false;
                    this.showQna2=false;
                    this.showPoll3=false;
                    this.showQna3=false;
                    this.setState({showQna1:false});
                    break;
                }
                case 'showQna2':{
                    this.showPoll1=false;
                    this.showQna1=false;
                    this.showPoll2=false;
                    this.showQna2=true;
                    this.showPoll3=false;
                    this.showQna3=false;
                    this.setState({showQna2:false});
                    break;
                }
                case 'showQna3':{
                    this.showPoll1=false;
                    this.showQna1=false;
                    this.showPoll2=false;
                    this.showQna2=false;
                    this.showPoll3=false;
                    this.showQna3=true;
                    this.setState({showQna3:false});
                    break;
                }
                default:{
                    this.showPoll1=false;
                    this.showQna1=false;
                    this.showPoll2=false;
                    this.showQna2=false;
                    this.showPoll3=false;
                    this.showQna3=false;
                    this.setState({
                        showPoll:false,
                        showQna:false,
        
                        showPoll1:false,
                        showQna1:false,
        
                        showPoll2:false,
                        showQna2:false,
        
                        showPoll3:false,
                        showQna3:false,
                    });
                    break;
                }
           }

        }

    }

    backToMainLobbyFromOtherState=()=>{
            this.setState({ menuItems:menuItems });
            this.setState({currentMenuState: "home-session"}); 

            this.hideAllMenu();
    }

    
    ///////////PPJ//////////////////

    handleContextMenu(menuItem) {
       
        this.setState({
            
            activeMenuLevel0: menuItem,
            activeMenuLevel1: null,
            activeMenuLevel2: null,
            activeMenuLevel3: null,
            lastActiveMenu: menuItem,
            currentMenuState: menuItem.id,
            showPoll:false,
            showQna:false,

            showPoll1:false,
            showQna1:false,         
        });
        
        if(menuItem.id==='qna-session'){
            this.showQna=true;
            this.showPoll=false;
            this.setState({showQna:true});
        }else{
            this.setState({showQna:false});
        }

        if(menuItem.id==='poll-session'){
            this.showPoll=true;
            this.showQna=false;
            this.setState({showPoll:true});
        }else{
            this.showPoll1=false;
            this.setState({showPoll:false});
        }
    }

    handleContextMenuLevel1(menuItem) {
       // console.log("going back Handle context menu level 1");
        //console.log(menuItem);

        if(menuItem.id === "home-session")
        {
            //console.log("here-----------------------------");
            //  this.callReactConnectorFunction(menuItem)
            window.canvasManager.mobileBack();
        }
        this.setState({
            activeMenuLevel0: menuItem,
            activeMenuLevel1: null,
            activeMenuLevel2: null,
            activeMenuLevel3: null
            // lastActiveMenu: menuItem,
            // currentMenuState: menuItem.id
        });
    }

    handleContextMenuLevel2(menuItem) {
        //console.log("++++++++++++++++");
       //console.log("going back Handle context menu level 2");
        //console.log(menuItem);
        //console.log("++++++++++++++++");
        if (menuItem.level === 2) {
            if (menuItem.parentMenu.parentMenu.id === 2) {//from audi this is being called
                this.setState({
                    activeMenuLevel0: null,
                    activeMenuLevel1: menuItem.parentMenu,
                    activeMenuLevel2: null,
                    activeMenuLevel3: null
                    // lastActiveMenu: menuItem.parentMenu,
                    // currentMenuState: menuItem.parentMenu.parentMenu.id
                });
            } else {
                this.setState({
                    activeMenuLevel0: null,
                    activeMenuLevel1: menuItem.parentMenu,
                    activeMenuLevel2: null,
                    activeMenuLevel3: null
                    // lastActiveMenu: menuItem.parentMenu,
                    // currentMenuState: menuItem.parentMenu.parentMenu.id
                });
            }
        }

        //console.log(this.state.activeMenuLevel2)
    }

    //#region hide Menu Functions
    hideMenuLevel1() {
        //this.setState({ activeMenuLevel0: null });
    }

    hideMenuLevel1x() {
        this.setState({ activeMenuLevel0: null });
    }

    hideMenuLevel2() {
        //this.setState({ activeMenuLevel0: null });
        //this.setState({ activeMenuLevel1: null });
    }

    hideMenuLevel3() {
        //this.setState({ activeMenuLevel0: null });
        //this.setState({ activeMenuLevel1: null });
        //this.setState({ activeMenuLevel2: null });
    }

    hideAllMenu()
    {
        if(this.state.activeMenuLevel0)
        {
            if(this.state.activeMenuLevel0.id === "profile")
            {
                this.setState({
                    currentMenuState: "home-session"
                });
            }
        }

        this.showQna=false;
        this.showPoll=false;
        this.showQna1=false;
        this.showPoll1=false;
        this.showQna2=false;
        this.showPoll2=false;
        this.showQna3=false;
        this.showPoll3=false;
        this.setState({ activeMenuLevel0: null });
        this.setState({ activeMenuLevel1: null });
        this.setState({ activeMenuLevel2: null });
        this.setState({ showPoll:false});
        this.setState({ showQna:false });
        this.setState({ showPoll1:false});
        this.setState({ showQna1:false });

        this.setState({ showPoll2:false});
        this.setState({ showQna2:false });

        this.setState({ showPoll3:false});
        this.setState({ showQna3:false });

        // console.log("HiddenX");
    }
    //#endregion

    //#region  openMenu
    OpenMenu = (menuItem) => {
       // console.log(menuItem);
        
        let activeMenuLevelArray = new Array(4).fill(null);

        if (activeMenuLevelArray.length >= menuItem.level) {
            activeMenuLevelArray[menuItem.level] = menuItem;
            this.setState({
                activeMenuLevel0: activeMenuLevelArray[0],
                activeMenuLevel1: activeMenuLevelArray[1],
                activeMenuLevel2: activeMenuLevelArray[2],
                activeMenuLevel3: activeMenuLevelArray[3],
                lastActiveMenu: menuItem,
                currentMenuState: menuItem.id

            });
        } else {
            //console.log("Please check value you are passing to Open Menu");
        }
    }

    OpenChildMenu = (menuItem, activeItemId) => {
        //console.log(menuItem);
        let activeMenuLevelArray = new Array(4).fill(null);
        if (activeMenuLevelArray.length >= menuItem.level) {
            activeMenuLevelArray[menuItem.level] = menuItem;
            this.setState({
                activeMenuLevel0: activeMenuLevelArray[0],
                activeMenuLevel1: activeMenuLevelArray[1],
                activeMenuLevel2: activeMenuLevelArray[2],
                activeMenuLevel3: activeMenuLevelArray[3],
                lastActiveMenu: menuItem,
                currentMenuState: activeItemId

            });
        } else {
            //console.log("Please check value you are passing to Open Menu");
        }
    }

    OpenMenuWithId(id)
    {
        if(id == null )
        {
            //console.log("Please check value passed in OpenMenu With Id");
            return;
        }else
        {
            if(id >= 0)
            this.OpenMenu(menuItems[id])
        }
    }
    //#endregion 

    onHeadingClick(event, parentMenu) {
        //console.log("heading handler");
        event.preventDefault();

        this.hideAllMenu();
        if (parentMenu.level == 0) {

            switch (parentMenu.id) {
                case 1:
                    //if lobby is clicked
                    window.canvasManager.mobileHome();
                    this.hideMenuLevel1();
                    break;
                case 2:
                    //if Auditorium is clicked
                    this.hideAllMenu();
                    break;
                case 3:
                    //if Halls is clicked
                    break;
            }
        } else if (parentMenu.level == 1) {
            this.handleContextMenuLevel1(parentMenu.parentMenu);
        } else if (parentMenu.level == 2) {
            this.handleContextMenuLevel2(parentMenu);
        }

    }

//Sub Menu button Click Controller FROM PLAYCANVAS - PPJ
//Reciving from playcanvas which button is clicked by user

handleSubmenuClickFromPlaycanvas(activeMenuLevel1) {
   // console.log(activeMenuLevel1);
 
    switch(activeMenuLevel1){
        case 'ConferenceHall':{          
            this.setState({ menuItems:conferencehall});  
            this.hideAllMenu();
            break;
        }

        case 'Pavillion1':{    
            if(!window.FirebaseObj.mediauser)      
            this.setState({ menuItems:AllBack });  
            else
            this.ToggleMenuLoading(false);

            this.hideAllMenu();
            break;
        }
        case 'Pavillion2':{          
            if(!window.FirebaseObj.mediauser)      
            this.setState({ menuItems:AllBack });  
            else
            this.ToggleMenuLoading(false);

            this.hideAllMenu();
            break;
        }       
        case 'MeetingRoom':{ //   //breakout1     
            if(this.context.isAllowedTownHall && window.FirebaseObj.uservalidforcall && window.FirebaseObj.breakoutcanenter==1)
            this.setState({ menuItems:Breakout1QP });
            else
             this.ToggleMenuLoading(false);             
            this.hideAllMenu();
            break;
        }     
        case 'MeetingRoom2':{ //   //breakout1     
            if(this.context.isAllowedTownHall && window.FirebaseObj.uservalidforcall && window.FirebaseObj.breakoutcanenter==2)
            this.setState({ menuItems:Breakout1QP });
            else
             this.ToggleMenuLoading(false);             
            this.hideAllMenu();
            break;
        } 
        case 'MeetingRoom3':{ //   //breakout1          
        
            if(this.context.isAllowedTownHall && window.FirebaseObj.uservalidforcall && window.FirebaseObj.breakoutcanenter==3)
            this.setState({ menuItems:Breakout1QP });
            else
             this.ToggleMenuLoading(false);             
            this.hideAllMenu();
            break;
        }       
        case 'Lounge':{
            if(!window.FirebaseObj.mediauser)            
            this.setState({ menuItems:Lounge }); 
            else
            this.ToggleMenuLoading(false);

            this.hideAllMenu();
            break;
        }
        case 'ExpoHall':{
            this.hideAllMenu();
            this.setState({ menuItems:AllBack });
            break;
        }
        case 'InformationDesk':{
            this.setState({ menuItems:AllBack });
            this.hideAllMenu();
            break;
        }

        case 'LobbyStall':{
            this.setState({ menuItems:AllBack });
            this.hideAllMenu();
            break;
        }
      
    }
}

//Sub Menu button Click Controller - PPJ
handleSubmenuClick(event, activeMenuLevel1) {
      // console.log(activeMenuLevel1);

        switch(activeMenuLevel1.id){
            case 'conferencehall':{
                this.setState({ menuItems:conferencehall });               
                window.canvasManager.LobbyCallsFromReact('goToConferenceHall');
                this.hideAllMenu();
                break;
            }   
            case 'Pavillion1':{   
                if(!window.FirebaseObj.mediauser)      
                this.setState({ menuItems:AllBack });  
                else
                this.ToggleMenuLoading(false);                   
               
                window.canvasManager.LobbyCallsFromReact('goToGeneralPavillion');
                this.hideAllMenu();
                break;
            }
            case 'Pavillion2':{    

                if(!window.FirebaseObj.mediauser)      
                this.setState({ menuItems:AllBack });  
                else
                this.ToggleMenuLoading(false);

                window.canvasManager.LobbyCallsFromReact('goToPrimePavillion');
                this.hideAllMenu();
                break;
            }           
            case 'metting':{
                if(this.context.isAllowedTownHall && window.FirebaseObj.uservalidforcall && window.FirebaseObj.breakoutcanenter==1)
                 this.setState({ menuItems:Breakout1QP });
                else
                 this.ToggleMenuLoading(false);               
                window.canvasManager.LobbyCallsFromReact('goToMeeting');
                this.hideAllMenu();
                break;
            }
            case 'metting2':{
                if(this.context.isAllowedTownHall && window.FirebaseObj.uservalidforcall && window.FirebaseObj.breakoutcanenter==2)
                this.setState({ menuItems:Breakout1QP });
                else
                 this.ToggleMenuLoading(false);               
                window.canvasManager.LobbyCallsFromReact('goToMeeting2');
                this.hideAllMenu();
                break;
            }
            case 'metting3':{
                if(this.context.isAllowedTownHall && window.FirebaseObj.uservalidforcall && window.FirebaseObj.breakoutcanenter==3)
                 this.setState({ menuItems:Breakout1QP });
                else
                 this.ToggleMenuLoading(false);               
                window.canvasManager.LobbyCallsFromReact('goToMeeting3');
                this.hideAllMenu();
                break;
            }
            case 'lounge':{
                if(!window.FirebaseObj.mediauser)            
                this.setState({ menuItems:Lounge }); 
                else
                this.ToggleMenuLoading(false);
                            
                window.canvasManager.LobbyCallsFromReact('goToLounge');
                this.hideAllMenu();
                break;
            }   
        }

        event.preventDefault();
    }

    handleSubContextMenuLevel3(menuItem) {
        //console.log("HandleSubcontextMenu Level3 ");

        menuItem.parentMenu = this.state.activeMenuLevel2;

        this.setState({ activeMenuLevel3: menuItem });

        if (menuItem.method) {
            window.canvasManager[menuItem.method](menuItem);
        }

        if(!menuItem.visibleOnClick)
        {
            this.hideMenuLevel3();
        }

    }

    handleSubContextMenuLevel2(menuItem) {
        if (menuItem.level !== 2) {
            //console.alert("menu item not of level 2");
        }
        menuItem.parentMenu = this.state.activeMenuLevel1;

        let saveMenuState = false;


        if (menuItem.parentMenu.parentMenu.id === 3) {
            saveMenuState = true;
        } else {
            saveMenuState = false;
        }

        if (saveMenuState) {
            this.setState({
                activeMenuLevel2: menuItem,
                lastActiveMenu: menuItem,
                currentMenuState: menuItem.parentMenu.parentMenu.id
            });
        } else {
            this.setState({ activeMenuLevel2: menuItem });
        }

        if (menuItem.method) {
            window.canvasManager[menuItem.method](menuItem);
        }
      

        if(!menuItem.visibleOnClick)
        {
            this.hideMenuLevel3();
        }
      
    }

    handleSubContextMenuLevel1(menuItem) {
        //console.log("HandleSubcontextMenu Level1");
        if(!menuItem.parentMenu)
            menuItem.parentMenu = this.state.activeMenuLevel0;

        let saveMenuState = false;

        if (menuItem.parentMenu.id === 1 && menuItem.id > 1) {
            saveMenuState = false;
        } else {
            saveMenuState = true;
        }
        if (saveMenuState) {

            this.setState({
                activeMenuLevel1: menuItem,
                lastActiveMenu: menuItem,
                currentMenuState: menuItem.parentMenu.id
            });

        } else {
            this.setState({ activeMenuLevel1: menuItem 
                // lastActiveMenu: menuItem.parentMenu,
                // currentMenuState: menuItem.parentMenu.id
            });
        }


        if (menuItem.method) {
            window.canvasManager[menuItem.method](menuItem);
        }     

        if(!menuItem.visibleOnClick)
        {
            this.hideMenuLevel3();
        }      

        this.hideAllMenu();
    }

    //#region menu utility functions
    ResetMenu = () =>
    {
        this.handleContextMenuLevel1(this.state.menuItems);
        this.hideAllMenu();
    }

    ToggleUIInteractbleState = (StateValue) => {
        this.setState({
            isInteractable: StateValue
        });
        //this state value will be passed as props for component to know if they can take action or not
       // console.log("UI is interaction stae: " + StateValue);
        

        if(StateValue === true)
        {
            //console.log("In Lobby");
            this.isLobby = true;
            this.setState({isLobby:true});
            
        } else
        {
            //console.log("Not In Lobby");
        }
    }

    ToggleMenuLoading = (value) => {
        this.setState({
                inTransition: value,
                isInteractable: !value
        });
        //will be called from playcanvas when some video request will be sent    
      //  console.log("isInteractable : "+ !value);

        if(value === false)
        {
            this.showHelp1();
        }
    }

    resetSubMenuActiveId()
    {
        this.setState({
            subMenuActiveId: -1
        });
    }

    setSubMenuActiveId(value)
    {
        //console.log(value);
        this.setState({
            subMenuActiveId: value
        });
    }
    //#endregion

    hideSubContextMenu() {
        this.setState({ activeMenuLevel1: null });
        this.setState({ activeMenuLevel2: null });
    }

    onSwipeUpClicked(expended) {
        this.setState({ expended: expended });
    }


    showNotification(notification){
        this.setState({notification});
        this.setState({showNotification: true});
        setTimeout(() => {
            this.setState({showNotification: false});
            this.setState({notification: null});
        }, notification.timeout);
    }

    IsShowingQnaOrPoll()
    {
        if(isMobileOnly)
        {
            if(this.showQna || this.showQna1 || this.showQna2 || this.showQna3 || this.showPoll || this.showPoll1 || this.showPoll2 || this.showPoll3 )
            {
                return true
            }else
            {
                return false
            }
        }else
        {
            return false;
        }        
    }

    //#region 
    removeAdminControlListener()
    {
        if(this.adminControlListener)
        {
            this.adminControlListener();
        }
        if(this.adminflowListener)
        {
            this.adminflowListener();
        }

        if(this.adminforceListener)
        {
            this.adminforceListener();
        }
    }

    callAuditoriumeVideoUpdateListener=()=>{
        
    }

    attachAdminControlListener()
    {
       var self = this;
       var firstTime = true;
       this.adminControlListener = this.context.db.collection('AdminControl').doc('notification').onSnapshot(function(doc) {
            let docData =doc.data();

             if(docData)
             {
                if(docData.showNotification)
                {
                   self.setState({showAdminNotification: true, textContent:docData.message });
                }else{
                   self.setState({showAdminNotification: false, textContent:docData.message });
                }
             }
        });


        this.context.db.collection('Admin').doc('eventManagement').onSnapshot(function(doc) {
            try{
            let docData =doc.data();       
        
            if(docData.audiurl)
                self.state.audiURL1=docData.audiurl;   
                    
            self.setState({audiURL1:docData.audiurl});
            //Update Audi Video URL
            if(window.canvasManager)
                window.canvasManager.UpdateAudiVideos();

              
            if(docData.zoomurl1)
            self.state.zoomURL1=docData.zoomurl1;                       
            self.setState({zoomURL1:docData.zoomurl1});

            if(docData.zoomurl2)
            self.state.zoomURL2=docData.zoomurl2;                       
            self.setState({zoomURL2:docData.zoomurl2});

            if(docData.zoomurl3)
            self.state.zoomURL3=docData.zoomurl3;                       
            self.setState({zoomURL3:docData.zoomurl3});

            }
            catch(err) {
                console.log(err+ ": reloading page");
               window.location.reload();
            }
            
           // audiURL1
        });



        this.adminflowListener = this.context.db.collection('AdminControl').doc('sessionflow').onSnapshot(function(doc) {
            let docData =doc.data();

             if(docData)
             {
                //  console.log(docData.LandD);
                 self.setState({
                    landDOpened: docData.LandD,
                    sessionOpenned: docData.session,
                 });
             }
        });

        this.adminforceListener = this.context.db.collection('AdminControl').doc('forceAction').onSnapshot(function(doc) {
            let docData =doc.data();

             if(docData)
             {
                //  console.log(docData.LandD);
                 self.setState({
                    moveToSession: docData.moveToSession
                 });

                 if(docData.moveToSession)
                 {
                    if(!firstTime)
                    {
                        window.canvasManager.mobileAuditorium1ForceFully();
                    }
                 }
                 firstTime = false;
             }
        });
    }


DailyCoSidebarhandler=()=>
{  
    
    if(slider==true)
    {
        //this.element.classList.add('stall-container popover-modal');
        document.getElementById('dailycoslider').style.visibility="visible";  
        slider=false;  
        this.setState({sidebar:false}) 
          
    }
    else
    {
       // this.sidebardailyco.add("style", 'z-index:0');
       document.getElementById('dailycoslider').style.visibility="hidden";
       this.setState({sidebar:true}) 
       slider=true;  
    }
}

    getSessionStatus()
    {
        return {status: true};
        //NOTE: NEEDs TO EDIT LATER BASED ON USER TYPE
        if(this.state.sessionOpenned)
        {
            return {status: true};
        }else{
            return {status: false, message: "Can not Enter Session Hall Right Now!!"};
        }
    }
    
    getLandDStatus()
    {
        return {status: true};
        //NOTE: NEEDs TO EDIT LATER BASED ON USER TYPE
        //console.log(this.state.landDOpened);
        if(this.state.landDOpened)
        {
            return {status: true};
        }else{
            return {status: false, message: "Can not Enter L&D Halls Right Now!!"};
        }
    }

    showInfoPopUp(message) {
        //console.log("showInfoPopUp", message);
        swal({
            title: message, // `Event will start at ${roomStatus.timeLeft}`,
            icon: "info",
            className: "video-swal-modal",
            button: "continue",
        });
    }

    getLastActiveMenu() {
        var lastMenu = null;
        if (this.state.activeMenuLevel0 !== null) {
            lastMenu = this.state.activeMenuLevel0;
        }
        else if (this.state.activeMenuLevel1 !== null) {
            lastMenu = this.state.activeMenuLevel1;
        }
        else if (this.state.activeMenuLevel2 !== null) {
            lastMenu = this.state.activeMenuLevel2;
        }
        else if (this.state.activeMenuLevel3 !== null) {
            lastMenu = this.state.activeMenuLevel3;
        }
        else {
            lastMenu = this.state.lastActiveMenu;
        }
        //console.log(lastMenu);
        return lastMenu;
    }

    OpenLastActiveMenu() {        
        this.handleClick(null, menuItems[1]);
        this.hideAllMenu();      
    }
    //#endregion

     // for dailyco
     removeTownHallLisenter = () => {
        if(this.townHallLisenter)
        {
            this.townHallLisenter();
        }
    }
    
    // for dailyco
    addTownHallLisenter = () => {
        var self = this;
        this.LiveParticipantListFromFirebase.clear();

        if(this.context.videoCallRoomName !== undefined && this.context.videoCallRoomName.length > 0)
        this.townHallLisenter = this.context.db
            .collection("dailyco")
            .doc(this.context.videoCallRoomName)
            .onSnapshot(function (doc) {
                if (doc.data()) {                   
                    const started = doc.data().callStarted;
                    self.canStartVideoCall = started;
                  //   console.log(self.canStartVideoCall +"call started");
                    if(self.canStartVideoCall)
                    {
                        const users = doc.data().users;
                        users.forEach(user => {
                            let userInfo =
                            {
                                userid: user.userid,
                                imageUrl: user.imageUrl
                             
                            }
                            self.LiveParticipantListFromFirebase.set(user.sessionId, userInfo);
                        });
                        // console.log(self.LiveParticipantListFromFirebase);
                    }    

                    self.setState({
                        updateLiveParticipantVideoCall: true
                    });             
                }
            });
        
    }

    getTownHallStatus()    {     
       
         if(this.context.isAllowedTownHall && window.FirebaseObj.uservalidforcall)            
            {
               
                return {status: "CanEnter"};
                if(this.context.videoRoomAdmin)
                {
                    return {status: "CanEnter"};
                }
                else
                {                   
                    if(this.canStartVideoCall)
                    {                        
                        return {status: "CanEnter"};
    
                    }else
                    {                        
                        return {status: "NoEntry", message: "Video Conference is not active right now."}
                    }
                }
            }
            else
            {
                return {status: "NoEntry", message: "Entry to breakout rooms is by invitation only."};
            } 
    }
    
    GetUserRoomEnterNumberStatus(name,number)
    {
        var self = this;     
        if(name=="breakoutroom")
        {
          if(!window.FirebaseObj.uservalidforcall)
             return {status: false, message: "Entry to this rooms is by invitation only."};

          if(number==window.FirebaseObj.breakoutcanenter) // admin have access to enter all room
            {               
                return {status: true};
            }              
           else
           {     
                if(window.FirebaseObj.breakoutcanenter==1)
                return {status: false, message: "Your breakout session is live at DBR - ONE"};
                else if(window.FirebaseObj.breakoutcanenter==2)
                return {status: false, message: "Your breakout session is live at DBR - TWO"};
                else if(window.FirebaseObj.breakoutcanenter==3)
                return {status: false, message: "Your breakout session is live at DBR - THREE"};  
                else
                return {status: false, message: "Entry to this rooms is by invitation only"};  
           }   
        }            
              
    }


    GetCallStartStatusforStreamVideo = () => {    
           
            this.context.db.collection('users').doc(authx.currentUser.uid).onSnapshot(function(doc) {
                   if (doc.data()) {
                    const data = doc.data()            
                    window.FirebaseObj.breakoutcanenter=data.meetingsessionbelongs;
                    window.FirebaseObj.uservalidforcall=data.uservalidforcall;                 
                }       
                
            });       
             
    }
    GetUserMediaPerson (name) 
    {      
       if(window.FirebaseObj.mediauser)       
        return {status: false, message: "This room is locked, Entry by invitation only"};
        else
        return {status: true};       
    }

    callEnterBreakout(flags)
    {
        this.state.enterbreakout=flags;
        this.setState({enterbreakout:flags});
        
        this.state.isInteractable=!flags;
        this.setState({isInteractable:!flags});
    }

    ChangeState = (event, menuItem) => {
        // window.canvasManager.mobileHome();
        this.callEnterBreakout(false);
        //window.canvasManager.LobbyAreaController(menuItem);    
        this.handleClick(event, menuItem);
     }
    
     RedirectToZoomCall(number)
     {
         var self=this;       
         switch(number)
         {
            case 14:
            {                
                window.open(self.state.zoomURL1); 
                break;
            }
            case 19:
            {
                window.open(self.state.zoomURL2); 
                break;
            }
            case 21:
            {
                window.open(self.state.zoomURL3); 
                break;
            }
         }

     }  

    //#endregion

    render() {
        const { expended } = this.state;

        return (
            <>
            
                {isMobileOnly ?       
                <div className="mobile-message">
                    
                </div>
                :null
                }
            
                <section onClick={event => this.hideMenuLevel3(event)} className="contentCntr">
                    <article className={"img-bg videoBox h-100 " + (this.IsShowingQnaOrPoll() ? "menu-active" : "")} id="play">
                    </article>
                </section>

                {/* {this.state.showHelp ?
                (
                    <button onClick={this.ShowTutorialAgain} className="helpBtnRX"></button>
                ):null}    */}

                   
                     <footer id="footer-box" className="footerBox">
                     <Menu itemstauts={this.state} items={this.state.menuItems} mainMenuState={this.state.currentMenuState} onMenuItemClick={this.handleClick} canInteract={this.state.isInteractable}></Menu>
                     
                     {this.state.activeMenuLevel0  ?
 
                         (<div className={`submenu-container active ${expended ? "" : "expended"} ${this.state.activeMenuLevel0.id == 5 ? "submenu-container--profile" : ""}`}>
 
                             {isMobileOnly ?
                             (
                                 <button onClick={this.hideAllMenu} className="closeBtn"><img src={CloseButton} alt="Close" width="30" height="30"></img></button> 
                             ):null}
                             
                             {!isMobileOnly ?
                             (
 
                                 <div className="Demo__container"> 
                                     <button onClick={this.hideAllMenu} className="closeBtn floatL">
                                         <img src={CloseButton} alt="Close" width="30" height="30"></img>
                                     </button> 
                                 </div>
                             ):null}
 
                             {this.state.activeMenuLevel0.id != 5 && this.state.menuItems.map((item) => (
                                 item.subMenus &&
                                 <Submenu key={item.id} item={item} subMenus={item.subMenus}
                                     activeMenuItem={this.state.activeMenuLevel0}
                                     onSubMenuItemClick={this.handleSubmenuClick}
                                     onHeadingClick={this.onHeadingClick}
                                     onSwipeUpClicked={this.onSwipeUpClicked}
                                     inTransition={this.state.inTransition}
                                 ></Submenu>
                             ))}
 
                             {this.state.activeMenuLevel0 && this.state.activeMenuLevel0.id == "profile" &&
                                 <Profile hideMenu={this.hideAllMenu}></Profile>
                             }
 
                         </div>)
                         : null}
 
 
                             {this.showQna? 
                             (
                                 <div className={`submenu-container active expended`}>
                                     { isMobileOnly ? (null):(
                                     <button onClick={this.hideAllMenu} className="closeBtn"><img src={CloseButton} alt="Close" width="30" height="30"></img></button>
                                     )}
                                     <AppQna onHeadingClick={this.hideAllMenu} ></AppQna>
                                 </div>
                             ):null}
 
                             {this.showQna1? 
                             (
                                 <div className={`submenu-container active expended`}>
                                     { isMobileOnly ? (null):(
                                     <button onClick={this.hideAllMenu} className="closeBtn"><img src={CloseButton} alt="Close" width="30" height="30"></img></button>
                                     )}
                                     <QNAL1 onHeadingClick={this.hideAllMenu} ></QNAL1>
                                 </div>
                             ):null}                          
 
                             {this.showPoll? 
                             (
                                 <div className={`submenu-container active expended`}>
                                     { isMobileOnly ? (null):(
                                     <button onClick={this.hideAllMenu} className="closeBtn"><img src={CloseButton} alt="Close" width="30" height="30"></img></button>
                                     )}
                                     <Poll onHeadingClick={this.hideAllMenu} ></Poll>
                                 </div>
                             ):null}
 
                             {this.showPoll1? 
                             (
                                 <div className={`submenu-container active expended`}>
                                     { isMobileOnly ? (null):(
                                     <button onClick={this.hideAllMenu} className="closeBtn"><img src={CloseButton} alt="Close" width="30" height="30"></img></button>
                                     )}
                                     <POLL1 onHeadingClick={this.hideAllMenu} ></POLL1>
                                 </div>
                             ):null}
                    
                 {          
                           
                      (window.FirebaseObj.currentUser && this.state.enterbreakout) ?
                      (
                          
                          <Dailyco
                              menuItem={menuItems[0]}
                              goToLobby={this.ChangeState}
                              name={window.FirebaseObj.currentUser.displayName}
                              roomName={window.FirebaseObj.videoCallRoomName}
                              isAdmin={window.FirebaseObj.videoRoomAdmin}
                              LiveParticipantListFromFirebase = {this.LiveParticipantListFromFirebase}
                              videoCallStillOn = {this.canStartVideoCall}>
                          </Dailyco>
                         
                      )
                      : (null)
                 }                       
                 </footer>
               

                { this.state.showNotification &&
                    <div className="call-end-notification">{this.state.notification.line1}<span>{this.state.notification.line2}</span>
                    </div>
                }

                {
                   this.state.showAdminNotification? 
                   (
                       <div className="call-end-notification">
                           {this.state.textContent}
                       </div>
                   ):null
                }

            </>
        );
    }
}

export default Home;
