export const saveDataLocally = (key,data) =>
{
    var jsonObject = JSON.stringify(data);
    localStorage.setItem(key, jsonObject)
}

export const getLocalData = (key) =>
{
    var jsonData =localStorage.getItem(key);
    if(jsonData)
    {
        if(typeof(jsonData) === "object")
        {
            return jsonData
        }else
        {
            return JSON.parse(jsonData)
        }
    }
    else
    {
        return null;
    }
}