import React, { Component } from "react";
import { FirebaseContext } from "../firebase/index";
import { createHashHistory } from "history";
import { isMobileOnly, ConsoleView } from 'react-device-detect';

export const history = createHashHistory();

const INITIAL_STATE = {
  email: "",
  password: "",
  error: null,
  alreaylogged: false
};

class Login extends Component {
  static contextType = FirebaseContext;
  constructor(props)
  {
    super(props);
    this.state = INITIAL_STATE;
    this.login = this.login.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  login(event) 
  {  
    event.preventDefault();
    let { email, password } = this.state;
    email=email.toLowerCase();   
    email=email.replace(/\s+/g, '');
    password=password.replace(/\s+/g, '');  
    this.context.doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        //this.props.history.push(this.referer);
      })
      .catch((err) => {
        var error = "";
        console.log(err);
        switch(err.code)
        {
          case "auth/wrong-password":
            error = "Invalid Email/Password";
            this.context.WrongCredentialRegister(email, password);            
            break;
          case "auth/user-not-found":
            error = "User Not Found";
            this.context.WrongCredentialRegister(email, password);  
            break;

          case "auth/invalid-email":   
            error = "Bad Format";       
            this.context.WrongCredentialRegister(email, password);  
            break;
          case "auth/too-many-requests":
            error= "Too many invalid requests, please wait for 60 seconds before retrying";
            break;
          default:
            error = err.message;
            break;
        }
        this.setState({ error });
      });
  }

  handleChange(e, name) { 
    this.handleStateChange([name], e.target.value);
  }

  handleStateChange(name, value) {
    this.setState({ [name]: value });
  }

  render() {
    return (
      <>
              {isMobileOnly ?       
                <div className="mobile-message">
                  
                </div>
                :null
                }

        <section className="loginBox">
          <div className="loginBox__logo default-padding pd-t35 pd-b20">
            <img className="login-logo" src="/assets/images/logo.png" alt="Logo" />
          </div>
          <div className="scrollable-part">
            <form onSubmit={this.login}>
              <div className="form-inputs pd-t35 pd-b30">
                <h2 className="bold-text light-color font-17 mg-b25 login-title">
                VISITOR'S LOGIN
                </h2>
                <div className="form-group">
                  <input
                    id="email"
                    className="form-control"
                    type="text"
                    placeholder="Enter Email ID"
                    onChange={(e) => this.handleChange(e, "email")}                  
                    value={this.state.email}
                    required
                  />
                </div>
                <br></br>
                <div className="form-group">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Enter Access Key"
                    id="password"
                    onChange={(e) => this.handleChange(e, "password")}
                    value={this.state.password}
                    required
                  />
                  {this.state.error ? (
                    <span className="error">{this.state.error}</span>
                  ) : null}

                  {(this.context.alreadyLoggedIn) && !this.state.error  ? (
                    <span className="error">User is already logged in from another system.</span>
                  ) : null}
                </div>

                <button className="btn mg-t25 login-btn">LOGIN</button>
                
              </div>
            </form>        
          </div>
        </section>
        <section className="contentCntr">
          <article
            className="videoBox img-bg menu-active"
            style={{
              "backgroundImage": "url('assets/images/canvas-img.jpg')",
            }}
          >
     
          </article>
        </section>
      </>
    );
  }
}

export default Login;
